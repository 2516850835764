<template>
  <section>
    <Loader v-if="loading"/>

    <b-form class="form-login custom-form text-left" @submit.prevent="submit">
      <div class="form-group">
        <label for="password">Contraseña</label>

        <FormInput
          id="password"
          type="password"
          maxlength="20"
          placeholder="Ingresa una contraseña nueva"
          :show-error="$v.form.password.$error && !$v.form.password.required"
          v-model="form.password"
        />

        <form-error
          message="Nueva contraseña es requerida"
          v-if="$v.form.password.$error && !$v.form.password.required"
        ></form-error>
        <form-error
          message="La contraseña debe tener más de 8 caracteres"
          v-if="$v.form.password.$error && !$v.form.password.minLength"
        ></form-error>
      </div>

      <div class="form-group">
        <label for="repeat-password">Repetir contraseña</label>

        <FormInput
          id="repeat-password"
          type="password"
          maxlength="20"
          placeholder="Ingresa la contraseña nuevamente"
          :show-error="$v.form.repeatPassword.$error && !$v.form.repeatPassword.required"
          v-model="form.repeatPassword"
        />

        <form-error
          message="Nueva contraseña es requerida"
          v-if="$v.form.repeatPassword.$error && !$v.form.repeatPassword.required"
        ></form-error>
        <form-error :message="error" v-if="error"></form-error>
      </div>

      <b-button
        class="button button-blue button-login"
        type="submit"
        variant="none"
        block
      >Guardar
      </b-button>
    </b-form>
  </section>
</template>

<script>
import {Alert, FormError, FormInput, Loader} from "wize-admin";
import {minLength, required} from "vuelidate/lib/validators";

import {SecurityService} from "@/core/services";

export default {
  components: {
    FormError,
    Loader,
    FormInput,
  },
  data() {
    return {
      form: {
        password: null,
        repeatPassword: null,
      },
      loading: false,
      error: null,
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
      },
      repeatPassword: {
        required
      },
    },
  },
  methods: {
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      if (this.form.password !== this.form.repeatPassword) {
        this.error = 'Las contraseñas no coinciden'
        return;
      }

      try {
        this.error = null;
        this.loading = true;
        await SecurityService.resetPassword({...this.form, recoveryToken: this.$route.params.token});
        this.loading = false;
        Alert.success("Se actualizó su contraseña correctamente");
        await this.$router.push({name: "login"});
      } catch (e) {
        console.error(e);
        if (e.errors && e.errors.message) this.error = e.errors.message;
        else this.error = "Hubo un error al intentar cambiar su contraseña";
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>

<style lang="stylus">
@import '../Styles/form-login';
</style>
