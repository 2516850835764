var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('div',{staticClass:"page-title-container"},[_c('h1',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.editing ? "Editar lote" : "Nuevo lote")+" ")])]),_c('div',{staticClass:"container px-0"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8"},[_c('b-card',{staticClass:"custom-card",staticStyle:{"max-width":"760px"},attrs:{"tag":"article","no-body":""}},[_c('b-card-header',{staticClass:"custom-card-header d-flex align-items-center",attrs:{"header-tag":"section"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.editing ? "Datos del lote" : "Datos del nuevo lote")+" ")])]),_c('b-card-body',{staticClass:"card-body custom-card-body px-0 pb-0"},[_c('form',{staticClass:"custom-form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"form-section pt-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"number"}},[_vm._v("Lote *")]),_c('FormInput',{attrs:{"id":"number","type":"text","placeholder":"Ingresar lote","show-error":_vm.$v.form.number.$error && !_vm.$v.form.number.required},model:{value:(_vm.form.number),callback:function ($$v) {_vm.$set(_vm.form, "number", $$v)},expression:"form.number"}}),(
                          _vm.$v.form.number.$error && !_vm.$v.form.number.required
                        )?_c('form-error',{attrs:{"message":"Lote es requerido"}}):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"paymentCode"}},[_vm._v("Código de pago")]),_c('FormInput',{attrs:{"id":"paymentCode","type":"text","placeholder":"Ingresar código de pago","show-error":_vm.$v.form.paymentCode.$error &&
                          !_vm.$v.form.paymentCode.integer},model:{value:(_vm.form.paymentCode),callback:function ($$v) {_vm.$set(_vm.form, "paymentCode", $$v)},expression:"form.paymentCode"}}),(
                          _vm.$v.form.paymentCode.$error &&
                          !_vm.$v.form.paymentCode.integer
                        )?_c('form-error',{attrs:{"message":"El código de pago debe ser un valor entero"}}):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"area"}},[_vm._v("Metraje *")]),_c('FormInput',{attrs:{"id":"area","type":"number","min":"0","placeholder":"Ingresar metraje","show-error":_vm.$v.form.area.$error && !_vm.$v.form.area.required},model:{value:(_vm.form.area),callback:function ($$v) {_vm.$set(_vm.form, "area", $$v)},expression:"form.area"}}),(_vm.$v.form.area.$error && !_vm.$v.form.area.required)?_c('form-error',{attrs:{"message":"Metraje es requerido"}}):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"percentage"}},[_vm._v("Porcentaje *")]),_c('FormInput',{attrs:{"id":"percentage","type":"number","min":"0","placeholder":"Ingresar porcentaje","show-error":_vm.$v.form.percentage.$error &&
                          !_vm.$v.form.percentage.required},model:{value:(_vm.form.percentage),callback:function ($$v) {_vm.$set(_vm.form, "percentage", $$v)},expression:"form.percentage"}}),(
                          _vm.$v.form.percentage.$error &&
                          !_vm.$v.form.percentage.required
                        )?_c('form-error',{attrs:{"message":"Porcentaje es requerido"}}):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"address"}},[_vm._v("Dirección fiscal")]),_c('FormInput',{attrs:{"id":"address","type":"text","placeholder":"Ingresar dirección fiscal"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"type"}},[_vm._v("Tipo *")]),_c('FormSelect',{attrs:{"id":"type","items":_vm.lotTypes,"defaultOption":"Elegir tipo","showError":_vm.$v.form.type.$error},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),(_vm.$v.form.type.$error && !_vm.$v.form.type.required)?_c('form-error',{attrs:{"message":"Perfil es requerido"}}):_vm._e()],1)])]),_c('div',{staticClass:"form-group-buttons text-right"},[_c('router-link',{staticClass:"button button-light",attrs:{"to":{ name: 'config-lots-list' }}},[_vm._v(" "+_vm._s(_vm.editing ? "Volver" : "Cancelar")+" ")]),_c('button',{staticClass:"button button-primary",attrs:{"type":"submit"}},[_vm._v(" Guardar ")])],1)])])])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }