<template>
  <section class="payment-codes-details" v-if="currentItem">
    <div class="dashboard-banner">
      <h1 class="dashboard-title">Datos del código de pago</h1>
    </div>

    <div class="card-details pb-0">
      <section class="section">
        <h2 class="subtitle my-0">Información general</h2>

        <div class="row">
          <div class="col-12">
            <label for="" class="label">Código de pago</label>
            <p class="text">
              {{ currentItem.code || "-" }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <label for="" class="label">Nombres</label>
            <p class="text" v-html="currentItem.names"></p>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <label for="" class="label">Documentos</label>
            <p class="text" v-html="currentItem.documents"></p>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <label for="" class="label">Teléfonos</label>
            <p class="text" v-html="currentItem.phones"></p>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <label for="" class="label">Correos electrónicos</label>
            <p class="text" v-html="currentItem.emails"></p>
          </div>
        </div>
      </section>

      <div class="section border-bottom-0">
        <h2 class="subtitle">Lotes</h2>

        <div class="row">
          <div class="col-12">
            <div class="table-responsive" v-if="currentItem.lots.length">
              <table class="custom-table w-100 mt-4">
                <thead>
                <tr>
                  <th>Código</th>
                  <th>Metraje</th>
                  <th>Porcentaje</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="o in currentItem.lots" :key="o.id">
                  <td class="text-center">{{ o.number || "-" }}</td>
                  <td class="text-center">
                    {{ o.area || "-" }}
                  </td>
                  <td class="text-center">{{ `${o.percentage}%` || "-" }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <p class="text" v-else>Sin registros.</p>
          </div>
        </div>

        <div class="row buttons-container">
          <div class="col text-right">
            <button
              @click="$router.push({ name: 'config-payment-codes-list' })"
              class="button button-light"
            >
              Volver
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {Alert, Constants, Util} from "wize-admin";

import {Constants as LocalConstants} from "@/core/utils";

import {PaymentCodeService} from "@/core/services";

export default {
  data() {
    return {
      itemId: this.$route.params.id,
      currentItem: null,
      selectedOption: null,
    };
  },
  methods: {
    getType(key) {
      return Util.searchValue(key, LocalConstants.LotTypes);
    },
    async load() {
      this.$store.dispatch("app/loading", true);

      try {
        let response = await PaymentCodeService.get(this.itemId);

        if (response.status === "OK") {
          this.currentItem = JSON.parse(JSON.stringify(response.payload));

          this.currentItem.names = this.setLineBreak(this.currentItem.names)
          this.currentItem.documents = this.setLineBreak(this.currentItem.documents)
          this.currentItem.phones = this.setLineBreak(this.currentItem.phones)
          this.currentItem.emails = this.setLineBreak(this.currentItem.emails)
        }
      } catch (error) {
        console.error(error);
        let message = error.errors.message;
        if (message == "No encontrado")
          message = Constants.Errors.RECORD_NOT_FOUND;
        Alert.error(message, () => this.$router.push({name: "payment-codes-list"}));
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    setLineBreak(value) {
      if (!value) {
        return null;
      }
      return value.split('|').join('<br /> <br />')
    }
  },
  async mounted() {
    await this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus" scoped>
@import "../Styles/payment-codes-details.styl"
</style>
