<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar lote" : "Nuevo lote" }}
      </h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <b-card
            tag="article"
            no-body
            style="max-width: 760px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex align-items-center"
            >
              <h3>
                {{ editing ? "Datos del lote" : "Datos del nuevo lote" }}
              </h3>
            </b-card-header>
            <b-card-body class="card-body custom-card-body px-0 pb-0">
              <form class="custom-form" @submit.prevent="save" novalidate>
                <div class="form-section pt-0">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="number">Lote *</label>
                        <FormInput
                          id="number"
                          type="text"
                          placeholder="Ingresar lote"
                          :show-error="
                            $v.form.number.$error && !$v.form.number.required
                          "
                          v-model="form.number"
                        />
                        <form-error
                          message="Lote es requerido"
                          v-if="
                            $v.form.number.$error && !$v.form.number.required
                          "
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="paymentCode">Código de pago</label>

                        <FormInput
                          id="paymentCode"
                          type="text"
                          placeholder="Ingresar código de pago"
                          :show-error="
                            $v.form.paymentCode.$error &&
                            !$v.form.paymentCode.integer
                          "
                          v-model="form.paymentCode"
                        />
                        <form-error
                          message="El código de pago debe ser un valor entero"
                          v-if="
                            $v.form.paymentCode.$error &&
                            !$v.form.paymentCode.integer
                          "
                        ></form-error>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="area">Metraje *</label>
                        <FormInput
                          id="area"
                          type="number"
                          min="0"
                          placeholder="Ingresar metraje"
                          :show-error="
                            $v.form.area.$error && !$v.form.area.required
                          "
                          v-model="form.area"
                        />
                        <form-error
                          message="Metraje es requerido"
                          v-if="$v.form.area.$error && !$v.form.area.required"
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="percentage">Porcentaje *</label>

                        <FormInput
                          id="percentage"
                          type="number"
                          min="0"
                          placeholder="Ingresar porcentaje"
                          :show-error="
                            $v.form.percentage.$error &&
                            !$v.form.percentage.required
                          "
                          v-model="form.percentage"
                        />

                        <form-error
                          message="Porcentaje es requerido"
                          v-if="
                            $v.form.percentage.$error &&
                            !$v.form.percentage.required
                          "
                        ></form-error>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="address">Dirección fiscal</label>
                        <FormInput
                          id="address"
                          type="text"
                          placeholder="Ingresar dirección fiscal"
                          v-model="form.address"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="type">Tipo *</label>
                        <FormSelect
                          id="type"
                          v-model="form.type"
                          :items="lotTypes"
                          defaultOption="Elegir tipo"
                          :showError="$v.form.type.$error"
                        />

                        <form-error
                          message="Perfil es requerido"
                          v-if="$v.form.type.$error && !$v.form.type.required"
                        ></form-error>
                      </div>
                    </div>
                  </div>
                  <div class="form-group-buttons text-right">
                    <router-link
                      :to="{ name: 'config-lots-list' }"
                      class="button button-light"
                    >
                      {{ editing ? "Volver" : "Cancelar" }}
                    </router-link>
                    <button class="button button-primary" type="submit">
                      Guardar
                    </button>
                  </div>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Alert, FormError, FormSelect, FormInput, Constants } from "wize-admin";

import { Constants as LocalConstants } from "@/core/utils";

import { LotService } from "@/core/services";
import { required, integer } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      form: {
        number: null,
        paymentCode: null,
        area: null,
        percentage: null,
        address: null,
        type: null,
      },
      lotTypes: [LocalConstants.LotTypes.HOUSE, LocalConstants.LotTypes.LAND],
      editing: false,
    };
  },
  components: {
    FormError,
    FormSelect,
    FormInput,
  },
  validations: {
    form: {
      number: { required },
      paymentCode: { integer },
      area: { required },
      percentage: { required },
      type: { required },
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
  },
  computed: {},
  methods: {
    async save() {
      this.$v.$touch();

      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -50,
          });
        });
        return;
      }

      this.$store.dispatch("app/loading", true);

      const data = { ...this.form };

      try {
        let response;
        if (!this.editing) response = await LotService.save(data);
        else response = await LotService.update(this.$route.params.id, data);

        Alert.success("Se almacenó la información correctamente");
        if (!this.editing) {
          this.$router.push({
            name: "config-lots-list",
            params: { id: response.payload.id },
          });
          this.editing = true;
        }
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await LotService.get(id);
        this.form = { ...response.payload };
        const paymentCode = response.payload.paymentCode;
        this.form.paymentCode = paymentCode ? paymentCode.code : null;
        this.editing = true;
      } catch (ex) {
        let message = ex.errors.message;
        if (message == "No encontrado")
          message = Constants.Errors.RECORD_NOT_FOUND;
        Alert.error(message, () =>
          this.$router.push({ name: "config-lots-list" })
        );
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    if (this.$route.params.id) this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus" scoped></style>
