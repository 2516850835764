import VoucherView from "../VoucherView.vue";
import VoucherBatchList from "../Pages/VoucherBatchList.vue";
import LoadDebts from "../Pages/LoadDebts.vue";
import LoadCreps from "../Pages/LoadCreps.vue";

const routes = [
  {
    path: '/deudas',
    component: VoucherView,
    name: 'vouchers-batch',
    children: [
      {
        path: '',
        name: 'vouchers-batch-list',
        component: VoucherBatchList
      },
      {
        path: 'importar-deudas',
        name: 'vouchers-batch-load-charges',
        component: LoadDebts
      },
      {
        path: 'importar-creps',
        name: 'vouchers-batch-load-payments',
        component: LoadCreps
      }
    ]
  },
]

export default routes
