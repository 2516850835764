import { http } from "@/http";

export default {
  list(params) {
    return http.get("/api/v1/voucher", { params });
  },
  markAsPaid(id, payload) {
    return http.post(`/api/v1/voucher/${id}/pay`, payload)
  },
  sendVoucher(id) {
    return http.post(`/api/v1/voucher/${id}/send`)
  },
  downloadVoucher(id) {
    return http.post(`/api/v1/voucher/${id}/download`, null, {
      responseType: 'blob'
    })
  },
  downloadVoucherList(params) {
    return http.get('/api/v1/voucher/download', {
      responseType: 'blob',
      params
    })
  }
};
