<template>
  <div>
    <div :class="`wrapper-page${isMenuOpen ? '' : '-closeSidebar'}`">
      <!-- Mobile -->
      <PageSidebarMobile :show="isMenuOpen && currentDevice != 'desktop'" />

      <!-- Desktop -->
      <page-sidebar
        title="LAS LAGUNAS"
        :isMenuOpen="isMenuOpen"
        :tooltipType="tooltipType"
        @toggleMenu="toggleMenu"
        v-if="currentDevice == 'desktop'"
      >
        <template #custom-header>
          <img
            src="@/core/assets/images/layout/logo-admin.png"
            alt="Las Lagunas"
            class="main-logo"
          />
          <span class="sidebar-title d-inline-block ml-2">LAS LAGUNAS</span>
        </template>

        <template #list-items>
          <!-- Portada -->
          <router-link
            :to="{ name: 'dashboard' }"
            tag="li"
            exact-active-class="active"
            class="d-flex sidebar-item"
            @mouseover.native="tooltipType = 'home-item'"
            @mouseleave.native="tooltipType = null"
          >
            <div class="d-flex align-items-center">
              <span class="menu-icon">
                <i class="ri-home-5-line"></i>
              </span>

              <span class="sidebar-item-text">Portada</span>

              <CustomTooltip
                custom-class="tooltip-sidebar"
                v-if="!isMenuOpen && tooltipType === 'home-item'"
                text="Portada"
              />
            </div>
          </router-link>

          <!-- Mantenimiento -->
          <router-link
            event=""
            tag="li"
            active-class="active"
            to="/mantenimiento"
            class="sidebar-item d-flex align-items-center"
            @click.native.prevent="
              currentSubmenu === 'config'
                ? setCurrentSubmenu(null)
                : setCurrentSubmenu('config')
            "
          >
            <div
              class="d-flex flex-column justify-content-center sidebar-item-container"
            >
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex"
                  @mouseover="tooltipType = 'config-item'"
                  @mouseleave="tooltipType = null"
                >
                  <span class="menu-icon">
                    <i class="ri-edit-line"></i>
                  </span>

                  <span class="sidebar-item-text">Mantenimiento</span>

                  <CustomTooltip
                    custom-class="tooltip-sidebar"
                    v-if="!isMenuOpen && tooltipType === 'config-item'"
                    text="Mantenimiento"
                  />
                </div>
              </div>
              <span
                :class="`sidebar-item-description ${
                  isCurrent('config') ? 'active' : ''
                }`"
              >
                Códigos de pago, Lotes
              </span>
            </div>
            <div v-if="isMenuOpen">
              <SidebarArrowTopIcon v-if="isCurrent('config')" />
              <SidebarArrowBottomIcon v-else />
            </div>
          </router-link>

          <!-- Submenu mantenimiento -->
          <section>
            <ul
              :class="`sidebar-submenu ${
                isCurrent('config') ? 'active' : ''
              } pl-0`"
            >
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="config-payment-codes-list"
                text="Códigos de pago"
              ></sub-item>
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="config-lots-list"
                text="Lotes"
              ></sub-item>
            </ul>
          </section>

          <router-link
            :to="{ name: 'vouchers-list' }"
            tag="li"
            active-class="active"
            class="d-flex sidebar-item"
            @mouseover.native="tooltipType = 'vouchers-items'"
            @mouseleave.native="tooltipType = null"
          >
            <div class="d-flex align-items-center">
              <span class="menu-icon">
                <i class="ri-file-text-line"></i>
              </span>

              <span class="sidebar-item-text active">Recibos</span>

              <CustomTooltip
                custom-class="tooltip-sidebar"
                v-if="!isMenuOpen && tooltipType === 'vouchers-items'"
                text="Recibos"
              />
            </div>
          </router-link>

          <router-link
            :to="{ name: 'vouchers-batch-list' }"
            tag="li"
            active-class="active"
            class="d-flex sidebar-item"
            @mouseover.native="tooltipType = 'vouchers-batch-item'"
            @mouseleave.native="tooltipType = null"
          >
            <div class="d-flex align-items-center">
              <span class="menu-icon">
                <i class="ri-file-download-line"></i>
              </span>

              <span class="sidebar-item-text">Deudas</span>

              <CustomTooltip
                custom-class="tooltip-sidebar"
                v-if="!isMenuOpen && tooltipType === 'vouchers-batch-item'"
                text="Deudas"
              />
            </div>
          </router-link>
        </template>
      </page-sidebar>

      <div class="wrapper-page__content">
        <page-header />
        <div class="wrapper-page__inner">
          <b-container fluid>
            <slot></slot>
          </b-container>
        </div>
      </div>

      <page-footer />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import {
  CustomTooltip,
  PageFooter,
  PageSidebar,
  SidebarArrowBottomIcon,
  SidebarArrowTopIcon,
  SubItem,
} from "wize-admin";

import PageHeader from "./PageHeader";
import PageSidebarMobile from "./layout/PageSidebarMobile";

export default {
  name: "PageBase",
  components: {
    PageHeader,
    PageSidebarMobile,
    PageSidebar,
    PageFooter,
    CustomTooltip,
    SubItem,
    SidebarArrowBottomIcon,
    SidebarArrowTopIcon,
  },
  data() {
    return {
      matchMediaMd: window.matchMedia("(max-width: 768px)"),
      tooltipType: null
    };
  },
  computed: {
    ...mapGetters({
      currentSubmenu: "app/getCurrentSubmenu",
      isMenuOpen: "app/isMenuOpen",
      currentDevice: "app/getCurrentDevice",
    }),
  },
  methods: {
    calcResize() {
      this.setCurrentDevice();

      this.lastWidth = window.innerWidth;

      window.addEventListener("resize", () => {
        const currentWidth = window.innerWidth;

        if (currentWidth !== this.lastWidth) {
          this.setCurrentDevice();
          this.lastWidth = currentWidth;
        }
      });
    },
    setCurrentDevice() {
      if (screen.width < 992) {
        this.$store.commit("app/setCurrentDevice", "mobile");
        this.toggleMenu(false);
      } else {
        this.$store.commit("app/setCurrentDevice", "desktop");
        this.toggleMenu(true);
      }
    },
    isMatchMedia() {
      if (this.matchMediaMd.matches) {
        this.setMenu(false);
      } else {
        this.setMenu(true);
      }
    },
    isCurrent(submenu) {
      return this.currentSubmenu.includes(submenu);
    },
    ...mapMutations({
      setCurrentSubmenu: "app/setCurrentSubmenu",
      setMenu: "app/setMenu",
      toggleMenu: "app/toggleMenu",
    }),
  },
  created() {
    this.matchMediaMd.addListener(this.isMatchMedia);
  },
  async mounted() {

    this.calcResize();
  },
};
</script>

<style lang="stylus">
.sidebar-item
  border-botton: 1px solid #2b2b2c !important

.plan
  max-width 100% !important
</style>
