import ConfigView from "../ConfigView.vue";
import LotsList from "../Pages/LotsList.vue";
import LotEditor from "../Pages/LotEditor.vue";
import LotDetails from "../Pages/LotDetails.vue";
import PaymentCodesList from "../Pages/PaymentCodesList.vue";
import PaymentCodeEditor from '../Pages/PaymentCodeEditor'
import PaymentCodeDetails from '../Pages/PaymentCodeDetails'

const routes = [
  {
    path: '/mantenimiento',
    component: ConfigView,
    name: 'config',
    children: [
      {
        path: 'lotes',
        name: 'config-lots-list',
        component: LotsList
      },
      {
        path: 'lotes/crear',
        name: 'config-lots-create',
        component: LotEditor
      },
      {
        path: 'lotes/:id/editar',
        name: 'config-lots-edit',
        component: LotEditor
      },
      {
        path: 'lotes/:id',
        name: 'config-lots-details',
        component: LotDetails
      },
      {
        path: 'codigos-de-pago',
        name: 'config-payment-codes-list',
        component: PaymentCodesList
      },
      {
        path: 'codigos-de-pago/crear',
        name: 'config-payment-codes-create',
        component: PaymentCodeEditor
      },
      {
        path: 'codigos-de-pago/:id/editar',
        name: 'config-payment-codes-edit',
        component: PaymentCodeEditor
      },
      {
        path: 'codigos-de-pago/:id',
        name: 'config-payment-codes-details',
        component: PaymentCodeDetails
      }
    ]
  },
]

export default routes
