<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Importar deudas</h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12">
          <b-card
            tag="article"
            no-body
            style="max-width: 640px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex justify-content-between align-items-center"
            >
              <h3>Carga de archivo .xls y .xlsx</h3>

              <button
                type="button"
                class="button-tooltip m-0"
                v-b-tooltip.hover
                title="Descargar plantilla"
                @click="downloadTemplate()"
              >
                <DownloadTableIcon />
              </button>
            </b-card-header>
            <b-card-body class="custom-card-body px-0 pb-0">
              <form class="custom-form" @submit.prevent="save" novalidate>
                <div class="form-section pt-0 border-0">
                  <div class="form-group d-flex">
                    <label for="type">Tipo de deuda *</label>

                    <div class="flex-1 pl-4">
                      <FormSelect
                        id="type"
                        v-model="form.deptOption"
                        :items="deptOptions"
                        defaultOption="Elegir tipo"
                        :showError="$v.form.deptOption.$error"
                      />

                      <form-error
                        message="Tipo de deuda"
                        v-if="
                          $v.form.deptOption.$error &&
                          !$v.form.deptOption.required
                        "
                      ></form-error>
                    </div>
                  </div>

                  <div>
                    <label for="type">Cargar archivo</label>

                    <file-input
                      @onChange="onChange"
                      idFile="relation-batch-file"
                      :acceptFile="['.xls', '.xlsx']"
                      ref="fileUpload"
                      class="mt-0"
                    />

                    <form-error
                      message="El archivo es requerido"
                      v-if="$v.file.$error && !$v.file.required"
                    ></form-error>
                  </div>

                  <div class="form-group-buttons text-right">
                    <router-link
                      :to="{ name: 'vouchers-batch-list' }"
                      class="button button-light"
                    >
                      Cancelar
                    </router-link>
                    <button class="button button-primary" type="submit">
                      Procesar
                    </button>
                  </div>

                  <div
                    class="table-container mt-4 overflow-hidden"
                    v-if="logsList.length"
                  >
                    <LogsTable :items="logsList" />
                  </div>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fileDownload from "js-file-download";

import {
  Alert,
  FileInput,
  FormError,
  DownloadTableIcon,
  Util,
  FormSelect,
} from "wize-admin";

import { VoucherBatchService } from "@/core/services";
import { required } from "vuelidate/lib/validators";

import { Constants as LocalConstants } from "@/core/utils";

import LogsTable from "../Components/LogsTable";

export default {
  data() {
    return {
      file: null,
      editing: false,
      form: {
        deptOption: null,
      },
      logsList: [],
      deptOptions: [
        LocalConstants.DebtOptions.MAINTENANCE,
        LocalConstants.DebtOptions.INSTALLMENT,
        LocalConstants.DebtOptions.PENALTY,
      ],
    };
  },
  components: {
    FileInput,
    FormError,
    DownloadTableIcon,
    LogsTable,
    FormSelect,
  },
  validations: {
    form: {
      deptOption: { required },
    },
    file: { required },
  },
  computed: {},
  methods: {
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;

      await this.$store.dispatch("app/loading", true);

      const data = Util.getFormData({
        file: this.file,
        type: this.form.deptOption,
      });

      try {
        const payload = (await VoucherBatchService.upload(data)).payload;

        if (payload.messages.length) {
          this.logsList = payload.messages.map((item) => {
            const [row, message] = item.split(": ");
            return { row, message };
          });
        }

        if (this.logsList.length) {
          Alert.error('Ocurrió un error, complete los campos necesarios e inténtelo nuevamente.')
          return;
        }

        Alert.success("Se ha realizado la carga correctamente");

        this.$router.push({name: 'vouchers-batch-list'})
      } catch (ex) {
        let error = ex.errors
          ? `${ex.errors.message}.`
          : "Ocurrió un error, por favor inténtelo nuevamente";
        Alert.error(error);
      } finally {
        this.$refs.fileUpload.remove();
        await this.$store.dispatch("app/loading", false);
      }
    },
    onChange(file) {
      this.file = file;
    },
    async downloadTemplate() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await VoucherBatchService.getTemplate();
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
};
</script>

<style lang="stylus" scoped></style>
