<template>
  <div class="page-container payment-code-editor">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar código de pago" : "Nuevo código de pago" }}
      </h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <b-card
            tag="article"
            no-body
            style="max-width: 760px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex align-items-center"
            >
              <h3>
                {{
                  editing
                    ? "Datos de código de pago"
                    : "Datos del nuevo código de pago"
                }}
              </h3>
            </b-card-header>
            <b-card-body class="custom-card-body px-0 pb-0">
              <form class="custom-form" @submit.prevent="save" novalidate>
                <div class="form-section pt-0">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group d-flex align-items-start">
                        <div class="label-container">
                          <label for="code">Código de pago *</label>
                        </div>

                        <div class="flex-1 ml-40">
                          <FormInput
                            id="code"
                            type="text"
                            placeholder="Ingresar código de pago"
                            :show-error="
                              $v.form.code.$error && !$v.form.code.required
                            "
                            v-model="form.code"
                          />

                          <form-error
                            message="Código de pago es requerido"
                            v-if="$v.form.code.$error && !$v.form.code.required"
                          ></form-error>
                        </div>
                      </div>

                      <div class="form-group d-flex align-items-start">
                        <div class="label-container">
                          <label for="slug">Nombres *</label>
                          <p class="label-description">
                            Si se utilizan varios nombres, emplea el símbolo de
                            barra vertical ( | ) para separarlos.
                          </p>
                        </div>

                        <div class="flex-1 ml-40">
                          <textarea
                            id="header"
                            placeholder="Ingresar nombres"
                            class="custom-form-control"
                            :class="{
                              'custom-form-control-error':
                                $v.form.names.$error && !$v.form.names.required,
                            }"
                            v-model="form.names"
                          ></textarea>

                          <form-error
                            message="Nombres son requeridos"
                            v-if="
                              $v.form.names.$error && !$v.form.names.required
                            "
                          ></form-error>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group d-flex align-items-start">
                        <div class="label-container">
                          <label for="documents">RUCs o DNIs</label>
                          <p class="label-description">
                            Si se utilizan varios rucs o dnis, emplea el símbolo
                            de barra vertical ( | ) para separarlos.
                          </p>
                        </div>

                        <div class="flex-1 ml-40">
                          <textarea
                            id="documents"
                            placeholder="Ingresar RUCs o DNIs"
                            class="custom-form-control"
                            v-model="form.documents"
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group d-flex align-items-start">
                        <div class="label-container">
                          <label for="phones">Teléfonos</label>
                          <p class="label-description">
                            Si se utilizan varios teléfonos, emplea el símbolo
                            de barra vertical ( | ) para separarlos.
                          </p>
                        </div>

                        <div class="flex-1 ml-40">
                          <textarea
                            id="phones"
                            placeholder="Ingresar teléfonos"
                            class="custom-form-control"
                            v-model="form.phones"
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group d-flex align-items-start mb-0">
                        <div class="label-container">
                          <label for="emails">Correos electrónicos *</label>
                          <p class="label-description">
                            Si se utilizan varios correos electrónicos, emplea
                            el símbolo de barra vertical ( | ) para separarlos.
                          </p>
                        </div>

                        <div class="flex-1 ml-40">
                          <textarea
                            id="emails"
                            placeholder="Ingresar correos electrónicos"
                            class="custom-form-control"
                            :class="{
                              'custom-form-control-error':
                                ($v.form.emails.$error && !$v.form.emails.required) || !$v.form.emails.maxEmails
                            }"
                            v-model="form.emails"
                          ></textarea>

                          <form-error
                            message="Correos electrónicos son requeridos"
                            v-if="
                              $v.form.emails.$error && !$v.form.emails.required
                            "
                          ></form-error>

                          <form-error
                            message="Puede ingresar como máximo 5 correos electrónicos"
                            v-if="!$v.form.emails.maxEmails"
                          ></form-error>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group-buttons text-right">
                    <router-link
                      :to="{ name: 'config-payment-codes-list' }"
                      class="button button-light"
                    >
                      {{ editing ? "Volver" : "Cancelar" }}
                    </router-link>
                    <button class="button button-primary" type="submit">
                      Guardar
                    </button>
                  </div>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Alert, Constants, FormInput, FormError } from "wize-admin";

import { required } from "vuelidate/lib/validators";

import { PaymentCodeService } from "@/core/services";

const maxEmails = (value) => {
  if (!value || typeof value !== "string") {
    return true;
  }

  const emailsArray = value
    .split("\n")
    .map((email) => email.trim())
    .filter((email) => email !== "");
  const emailsCount = emailsArray.length;

  return emailsCount <= 5;
};

export default {
  data() {
    return {
      form: {
        code: null,
        names: null,
        documents: null,
        phones: null,
        emails: null,
      },
      editing: false,
    };
  },
  components: {
    FormInput,
    FormError,
  },
  validations: {
    form: {
      code: { required },
      names: { required },
      emails: { required, maxEmails },
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
  },
  methods: {
    async onChange(files) {
      try {
        let uploadedfiles = await this.uploadFiles(files);

        this.form.assetId = uploadedfiles[0].id;
      } catch (error) {
        alert("Error");
        console.error(error);
      }
    },
    removeImage() {
      this.form.image = null;
      this.form.assetId = null;
    },
    async save() {
      this.$v.$touch();

      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -50,
          });
        });
        return;
      }

      this.$store.dispatch("app/loading", true);

      let data = { ...this.form };

      const namesFormat = this.setSeparator(data.names);
      const documentsFormat = this.setSeparator(data.documents);
      const phonesFormat = this.setSeparator(data.phones);
      const emailsFormat = this.setSeparator(data.emails);

      data.names = namesFormat;
      data.documents = documentsFormat;
      data.phones = phonesFormat;
      data.emails = emailsFormat;

      try {
        let response;
        if (!this.editing) response = await PaymentCodeService.save(data);
        else
          response = await PaymentCodeService.update(
            this.$route.params.id,
            data
          );

        Alert.success("Se almacenó la información correctamente");
        if (!this.editing) {
          this.$router.push({
            name: "config-payment-codes-edit",
            params: { id: response.payload.id },
          });
          this.editing = true;
        }
      } catch (ex) {
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await PaymentCodeService.get(id);
        this.form = { ...response.payload };

        const namesFormat = this.setLineBreak(this.form.names);
        const documentsFormat = this.setLineBreak(this.form.documents);
        const phonesFormat = this.setLineBreak(this.form.phones);
        const emailsFormat = this.setLineBreak(this.form.emails);

        this.form.names = namesFormat;
        this.form.documents = documentsFormat;
        this.form.phones = phonesFormat;
        this.form.emails = emailsFormat;

        this.editing = true;
      } catch (ex) {
        let message = ex.errors.message;
        if (message == "No encontrado")
          message = Constants.Errors.RECORD_NOT_FOUND;
        Alert.error(message, () =>
          this.$router.push({ name: "config-payment-codes-list" })
        );
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    setSeparator(value) {
      if (!value) {
        return null;
      }
      let list = value.split("|")
      list.forEach(item => item.trim())

      return list.join("|");
    },
    setLineBreak(value) {
      if (!value) {
        return null;
      }
      return value.split("|").join(" | ");
    },
  },
  async created() {
    if (this.$route.params.id) this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus">
@import "../Styles/payment-code-editor.styl"
</style>
