const LotTypes = {
  HOUSE: {
    key: 1,
    label: "Casa"
  },
  LAND: {
    key: 2,
    label: "Terreno"
  },
};

const VoucherStatus = {
  PENDING: {
    key: 1,
    label: "PENDIENTE",
    color: "badge-gray"
  },
  PAID: {
    key: 2,
    label: "PAGADO",
    color: "badge-green"
  }
}

const VoucherProcessStatus = {
  PENDING: {
    key: 1,
    voucherLabel: "PENDIENTE",
    voucherBatchLabel: "PENDIENTE",
    color: "badge-gray"
  },
  GENERATING: {
    key: 2,
    voucherLabel: "GENERANDO",
    voucherBatchLabel: 'GENERANDO RECIBOS',
    color: "badge-yellow"
  },
  GENERATED: {
    key: 3,
    voucherLabel: "GENERADO",
    voucherBatchLabel: 'RECIBOS GENERADOS',
    color: "badge-yellow"
  },
  SENDING: {
    key: 4,
    voucherLabel: "ENVIANDO",
    voucherBatchLabel: 'ENVIANDO RECIBOS',
    color: "badge-green"
  },
  SENT: {
    key: 5,
    voucherLabel: "ENVIADO",
    voucherBatchLabel: 'RECIBOS ENVIADOS',
    color: "badge-green"
  }
}

const PaymentMethods = {
  WIRE_TRANSFER: {
    key: 1,
    label: "Transferencia interbancaria",
  },
  AUTOMATIC_DEBITS: {
    key: 2,
    label: "Débito automático"
  },
  CREPS: {
    key: 3,
    label: "CREPS"
  }
}

const DebtOptions = {
  MAINTENANCE: {
    key: 1,
    label: "Mantenimiento"
  },
  INSTALLMENT: {
    key: 2,
    label: "Cuota Extraordinaria"
  },
  PENALTY: {
    key: 3,
    label: "Penalidad"
  }
};

export default {
  LotTypes,
  VoucherStatus,
  VoucherProcessStatus,
  PaymentMethods,
  DebtOptions
};
