import Vue from "vue";
import VueRouter from "vue-router";
import LoginRoutes from "@/modules/Login/Routes";
import DashboardRoutes from "@/modules/Dashboard/Routes";
import ConfigRoutes from "@/modules/Config/Routes";
import VoucherRoutes from "@/modules/Voucher/Routes";
import VoucherBatchRoutes from "@/modules/VoucherBatch/Routes";
import store from "../store";

Vue.use(VueRouter);

let routes = [];

routes = routes.concat(
  LoginRoutes,
  DashboardRoutes,
  ConfigRoutes,
  VoucherRoutes,
  VoucherBatchRoutes
);

// Asignar redirección cuando una página no exista
let notFound = {path: "/:pathMatch(.*)*", name: "NotFound", redirect: {name: "dashboard"}}
routes.push(notFound)

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.excludeAuth) {
    if (!store.getters["auth/isAuthenticated"]) {
      next();
    } else {
      next({name: "dashboard"});
    }
  } else {
    if (store.getters["auth/isAuthenticated"]) {
      if (store.getters["auth/isUserLoaded"]) {
        next();
      } else {
        await store.dispatch("auth/setUser");
        next();
      }
    } else {
      next({name: "login"});
    }
  }
});

export default router;
