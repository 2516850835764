import { http } from "@/http";

export default {
  list(params) {
    return http.get("/api/v1/lot", { params });
  },
  get(id) {
    return http.get(`/api/v1/lot/${id}`);
  },
  save(payload) {
    return http.post("/api/v1/lot", payload);
  },
  update(id, payload) {
    return http.put(`/api/v1/lot/${id}`, payload);
  },
  toggleStatus(id, isActive) {
    return http.put(`/api/v1/lot/${id}/active`, null, {
      params: {
        isActive,
      },
    });
  },
  delete(id) {
    return http.delete(`/api/v1/lot/${id}`);
  },
  getUserInfo() {
    return http.get("/api/v1/auth/me");
  }
};
