import { http } from "@/http";

export default {
  list(params) {
    return http.get("/api/v1/payment-code", { params });
  },
  listAll() {
    return http.get('/api/v1/payment-code/all')
  },
  get(id) {
    return http.get(`/api/v1/payment-code/${id}`);
  },
  save(payload) {
    return http.post("/api/v1/payment-code", payload);
  },
  update(id, payload) {
    return http.put(`/api/v1/payment-code/${id}`, payload);
  },
  toggleStatus(id, isActive ) {
    return http.put(`/api/v1/payment-code/${id}/status`, null, {
      params: {
        isActive
      }
    });
  },
  delete(id) {
    return http.delete(`/api/v1/payment-code/${id}`);
  },
  updateVisibleCategories(payload) {
    return http.put('/api/v1/payment-code/visible', payload)
  }
};
