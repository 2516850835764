<template>
  <div class="page-container voucher-list">
    <div class="page-title-container">
      <h1 class="page-title">Deudas</h1>
    </div>

    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-100 d-flex justify-content-between">
            <button
              type="button"
              class="d-flex align-items-center justify-content-between border-0 filter"
              @click="showFilters = !showFilters"
            >
              <FiltersIcon />
              <div>Filtrar deudas</div>
            </button>

            <div class="d-md-none">
              <button
                type="button"
                class="button-tooltip m-0 mr-lg-2"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <RefreshTableIcon />
              </button>
            </div>
          </div>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>

          <section
            class="w-100 d-flex align-items-start align-items-lg-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip mt-1 mt-lg-0"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <RefreshTableIcon />
              </button>
            </div>

            <div class="d-flex flex-column flex-lg-row flex-1">
              <SearchBar
                placeholder="Buscar por nombre de archivo"
                @search="onSearch"
                @input="search = $event"
                class="w-100"
              />

              <div class="text-right mt-2 d-lg-flex mt-lg-0">
                <b-button
                  :to="{ name: 'vouchers-batch-load-charges' }"
                  variant="none"
                  class="button button-primary mx-md-2"
                  >Importar deudas</b-button
                >

                <b-button
                  :to="{ name: 'vouchers-batch-load-payments' }"
                  variant="none"
                  class="button button-primary ml-2 ml-md-0"
                  >Importar creps</b-button
                >
              </div>
            </div>
          </section>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filters"
            v-bind:key="name"
            class="d-flex justify-content-between mr-2 filter-secondary"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="p-0 bg-transparent border-0"
              @click="deleteFilter(name)"
            >
              <CloseBorderRoundedIcon class="delete-filter-item" />
            </button>
          </span>
        </div>
      </div>
      <div>
        <!-- Mobile -->
        <table class="custom-table position-relative w-100 d-xl-none">
          <thead>
            <tr>
              <th class="text-left px-4">
                Fecha de carga
                <span class="d-inline-block ml-3">Nombre de archivo	</span>
              </th>
            </tr>
          </thead>
          <tbody v-if="list.length">
            <tr v-for="o in list" :key="o.id">
              <td class="px-4">
                <div class="d-flex justify-content-between align-items-start">
                  <div>
                    <p class="my-0">
                      <span v-localDate="o.uploadDate" v-if="o.uploadDate"></span>
                        <span v-else>-</span>
                      <span class="d-inline-block ml-3">{{
                        o.filename || "-"
                      }}</span>
                    </p>

                    <div class="table-mobile-options mt-2">
                      <a
                        href="#"
                        class="mr-2"
                        @click.prevent="openGeneratePdfModal(o)"
                        v-if="showGeneratePdfOption(o)"
                      >
                        Generar PDFs
                      </a>

                      <a
                        href="#"
                        class="mt-2 mr-2"
                        @click.prevent="openSendEmailsModal(o)"
                        v-if="showSendEmailOption(o)"
                      >
                        Enviar emails
                      </a>

                      <a
                        href="#"
                        class="mt-2 mr-2"
                        @click.prevent="downloadMacro(o)"
                        v-if="showDownloadMacroOption(o)"
                      >
                        Descargar macro txt
                      </a>

                      <a
                        href="#"
                        class="mt-2 mr-2"
                        @click="openDelete(o)"
                        v-if="showDeleteOption(o)"
                      >
                        Eliminar
                      </a>
                    </div>
                  </div>

                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>

                <div class="pt-3" v-if="o.showMobileData">
                  <ul class="list-unstyled p-0">
                    <li class="mb-1">
                      <span class="font-weight-bold d-inline-block mr-1">Importe total:</span>
                      <span v-decimal:pen="o.total" v-if="o.total"></span>
                      <span v-else>-</span>
                    </li>
                    <li class="mb-1">
                      <span class="font-weight-bold d-inline-block mr-1">Cantidad de recibos:</span>
                      <span class="d-inline-block">{{ o.itemCount || "-" }}</span>
                    </li>
                    <li class="mb-1">
                      <span class="font-weight-bold d-inline-block mr-1">Estado:</span>
                      <span :class="`badge ${getStatus(o.status).color}`">{{
                        getStatus(o.status).voucherBatchLabel
                      }}</span>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>

          <p class="empty-table-text text-center" v-else>
            No se encontraron registros.
          </p>
        </table>

        <!-- Desktop -->
        <table class="custom-table w-100 position-relative d-none d-xl-table">
          <thead>
            <tr>
              <th>Fecha de carga</th>
              <th>Nombre de archivo</th>
              <th>Importe total</th>
              <th>Cantidad de recibos</th>
              <th>Estado</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody v-if="list.length">
            <tr v-for="o in list" :key="o.id">
              <td class="text-center">
                <span v-localDate="o.uploadDate" v-if="o.uploadDate"></span>
                <span v-else>-</span>
              </td>
              <td class="text-center">{{ o.filename || "-" }}</td>
              <td class="text-center">
                <span v-decimal:pen="o.total" v-if="o.total"></span>
                <span v-else>-</span>
              </td>
              <td class="text-center">{{ o.itemCount || "-" }}</td>
              <td class="text-center">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                  getStatus(o.status).voucherBatchLabel
                }}</span>
              </td>

              <td class="text-center d_option">
                <DropdownTable v-if="showTableOptions(o)">
                  <template slot="dropdown-content">
                    <DropdownItem
                      text="Generar recibos"
                      @click="openGeneratePdfModal(o)"
                      v-if="showGeneratePdfOption(o)"
                    />

                    <DropdownItem
                      text="Enviar recibos"
                      @click="openSendEmailsModal(o)"
                      v-if="showSendEmailOption(o)"
                    />

                    <DropdownItem
                      text="Descargar macro txt"
                      @click="downloadMacro(o)"
                      v-if="showDownloadMacroOption(o)"
                    />

                    <DropdownItem
                      text="Eliminar"
                      @click="openDelete(o)"
                      v-if="showDeleteOption(o)"
                    />
                  </template>
                </DropdownTable>

                <span v-else>-</span>
              </td>
            </tr>
          </tbody>

          <p
            class="font-weight-normal empty-table-text text-center pt-2"
            v-else
          >
            No se encontraron registros.
          </p>
        </table>
      </div>
      <div
        class="table-footer d-flex justify-content-end align-items-center"
        v-if="list.length"
      >
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>

    <custom-modal
      :show="customModal.showModal"
      :confirmButtonText="customModal.confirmButtonText"
      @toAction="customModalResult($event)"
      :title="customModal.title"
    >
      <template slot="modal-content">
        <simple-alert :text="customModal.message" type="light-red">
          <template slot="icon">
            <WarningAlertIcon />
          </template>
        </simple-alert>
      </template>
    </custom-modal>
  </div>
</template>

<script>
import fileDownload from "js-file-download";

import {
  Alert,
  Constants,
  Util,
  PaginationMixin,
  FilterMixin,
  DropdownTable,
  DropdownItem,
  SearchBar,
  FilterMenu,
  FiltersIcon,
  CloseBorderRoundedIcon,
  RefreshTableIcon,
  SimpleAlert,
  WarningAlertIcon,
  CustomModal
} from "wize-admin";

import { required } from "vuelidate/lib/validators";

import { Constants as LocalConstants } from "@/core/utils";

import { VoucherBatchService } from "@/core/services";

export default {
  components: {
    SearchBar,
    FilterMenu,
    DropdownTable,
    DropdownItem,
    FiltersIcon,
    CloseBorderRoundedIcon,
    RefreshTableIcon,
    CustomModal,
    SimpleAlert,
    WarningAlertIcon,
  },
  mixins: [PaginationMixin, FilterMixin],
  data() {
    return {
      list: [],
      tooltipType: null,
      selectedOption: null,
      filterOptions: [
        {
          label: "Estado",
          key: "status",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.VoucherProcessStatus.PENDING,
            LocalConstants.VoucherProcessStatus.GENERATED,
            LocalConstants.VoucherProcessStatus.SENT,
          ],
        },
        {
          label: "Fecha de registro",
          key: "date",
          type: Constants.TypeFilters.DATE,
        },
      ],
      markAsPaidModal: {
        show: false,
        form: {
          paymentMethod: null,
          payedAmount: null,
        },
        paymentTypeList: [
          LocalConstants.PaymentMethods.WIRE_TRANSFER,
          LocalConstants.PaymentMethods.AUTOMATIC_DEBITS,
        ],
      },
      customModal: {
        showModal: false,
        title: null,
        message: null,
        confirmButtonText: null,
        action: null,
      },
    };
  },
  validations: {
    markAsPaidModal: {
      form: {
        paymentMethod: { required },
        payedAmount: { required },
      },
    },
  },
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "createdAt,desc",
          search: this.search,
          ...this.filters,
        };
        const response = await VoucherBatchService.list(params);
        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        });

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.VoucherProcessStatus);
    },
    resetCustomModal() {
      this.customModal.showModal = false;
      this.customModal.title = null;
      this.customModal.message = null;
      this.customModal.confirmButtonText = null;
    },
    customModalResult(value) {
      if (value == "cancel") {
        this.selectedOption = null;
        this.resetCustomModal();
      } else {
        if (this.customModal.action == "delete") {
          this.deleteItem();
        } else if (this.customModal.action == "generate") {
          this.generatePdf();
        } else if (this.customModal.action == "send") {
          this.send();
        }
      }
    },
    showGeneratePdfOption(o) {
      const { VoucherProcessStatus } = LocalConstants;
      let list = [VoucherProcessStatus.PENDING.key];

      return list.includes(o.status);
    },
    showSendEmailOption(o) {
      const { VoucherProcessStatus } = LocalConstants;
      let list = [VoucherProcessStatus.GENERATED.key];

      return list.includes(o.status);
    },
    showDownloadMacroOption(o) {
      const { VoucherProcessStatus } = LocalConstants;
      let list = [
        VoucherProcessStatus.PENDING.key,
        VoucherProcessStatus.GENERATING.key,
        VoucherProcessStatus.GENERATED.key,
        VoucherProcessStatus.SENDING.key,
        VoucherProcessStatus.SENT.key,
      ];

      return list.includes(o.status);
    },
    showDeleteOption(o) {
      const { VoucherProcessStatus } = LocalConstants;
      let list = [
        VoucherProcessStatus.PENDING.key,
        VoucherProcessStatus.GENERATED.key,
      ];

      return list.includes(o.status);
    },
    showTableOptions() {
      return true;
    },
    openSendVoucherByEmailModal(o) {
      this.selectedOption = o;

      this.openConfirmationModal({
        title: "Enviar por email",
        description: "¿Está seguro de enviar por email?",
        acceptText: "Aceptar",
        action: "send",
      });
    },
    async send() {
      this.$store.dispatch("app/loading", true);

      try {
        let response = await VoucherBatchService.send(this.selectedOption.id);

        if (response.status == "OK") {
          // Cambiar estado a "ENVIANDO"
          let item = this.list.find(o => o.id == this.selectedOption.id)
          item.status = LocalConstants.VoucherProcessStatus.SENDING.key

          Alert.success("Se envió la información correctamente");

          this.selectedOption = null;
        } else {
          Alert.error("Ocurrió un error, inténtelo nuevamente.");
        }
      } catch (ex) {
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
        this.resetCustomModal();
      }
    },
    async generatePdf() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await VoucherBatchService.generateVoucher(
          this.selectedOption.id
        );

        if (response.status == "OK") {
          // Cambiar estado a "GENERATING"
          let item = this.list.find(o => o.id == this.selectedOption.id)
          item.status = LocalConstants.VoucherProcessStatus.GENERATING.key

          this.selectedOption = null;

          Alert.success("El documento se ha generado correctamente.");
        }
      } catch (error) {
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
        this.resetCustomModal();
      }
    },
    async downloadMacro(o) {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await VoucherBatchService.downloadMacro(
          o.id
        );

        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    openDelete(o) {
      this.selectedOption = o;

      this.customModal.showModal = true;
      this.customModal.title = "Eliminar";
      this.customModal.message =
        "Esta decisión es irreversible. Tener en cuenta que se eliminará la carga de cobros y todos los recibos en PDF en caso hayan sido generados.";
      this.customModal.confirmButtonText = "Eliminar";
      this.customModal.action = "delete";
    },
    openGeneratePdfModal(o) {
      this.selectedOption = o;
      this.customModal.showModal = true;
      this.customModal.title = "Generar recibos en PDF";
      this.customModal.message =
        "Esta decisión es irreversible. Por lo tanto, es esencial que confirme la generación de documentos.";
      this.customModal.confirmButtonText = "Generar";
      this.customModal.action = "generate";
    },
    openSendEmailsModal(o) {
      this.selectedOption = o;
      this.customModal.showModal = true;
      this.customModal.title = "Enviar correos electrónicos";
      this.customModal.message =
        "Esta decisión es irreversible. Por lo tanto, es esencial que confirme el envío de correos.";
      this.customModal.confirmButtonText = "Enviar";
      this.customModal.action = "send";
    },
    async deleteItem() {
      try {
        await this.$store.dispatch("app/loading", true);
        await VoucherBatchService.delete(this.selectedOption.id);

        this.selectedOption = null;

        Alert.success("Se ha eliminado la información correctamente");
        await this.loadData();
      } catch (ex) {
        console.error(ex);
      } finally {
        await this.$store.dispatch("app/loading", false);
        this.resetCustomModal();
      }
    },
  },
  computed: {
    showFiltersList() {
      return Object.keys(this.filters).length >= 1;
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<style lang="stylus">
@import "../Styles/voucher-batch-list.styl"
</style>
