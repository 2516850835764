import VoucherView from "../VoucherView.vue";
import VoucherList from "../Pages/VoucherList.vue";

const routes = [
  {
    path: '/recibos',
    component: VoucherView,
    name: 'vouchers',
    children: [
      {
        path: '',
        name: 'vouchers-list',
        component: VoucherList
      }
    ]
  },
]

export default routes
