import { http } from "@/http";

export default {
  list(params) {
    return http.get("/api/v1/voucher-batch", { params });
  },
  get(id) {
    return http.get(`/api/v1/voucher-batch/${id}`);
  },
  delete(id) {
    return http.delete(`/api/v1/voucher-batch/${id}`);
  },
  generateVoucher(id) {
    return http.put(`/api/v1/voucher-batch/${id}/generate`, {
      responseType: 'blob'
    })
  },
  downloadMacro(id) {
    return http.get(`/api/v1/voucher-batch/${id}/download/macro`, {
      responseType: 'blob'
    })
  },
  upload(payload) {
    return http.post('/api/v1/voucher-batch/upload', payload)
  },
  send(id) {
    return http.put(`/api/v1/voucher-batch/${id}/send`)
  },
  getTemplate() {
    return http.get('/api/v1/voucher-batch/download/template', {
      responseType: 'blob'
    })
  }
};
