import {http} from '@/http'
import { Util } from "wize-admin";

export default {
  login(payload) {
    const formData = Util.getFormData(payload);
    return http.post('/api/v1/auth', formData, {headers: { "Content-Type": "multipart/form-data" },})
  },
  isValidToken() {
    return http.get('/api/v1/auth/token')
  },
  recoverPassword(data) {
    return http.put('/api/v1/auth/password', data)
  },
  resetPassword(data) {
    return http.post('/api/v1/auth/password', data)
  }
}
