<template>
  <section class="order-details" v-if="currentItem">
    <div class="dashboard-banner">
      <h1 class="dashboard-title">Datos del lote</h1>
    </div>

    <div class="card-details pb-0">
      <section class="section border-bottom-0">
        <h2 class="subtitle my-0">Información general</h2>

        <div class="row">
          <div class="col-md-6">
            <label for="" class="label">Lote</label>
            <p class="text">
              {{ currentItem.number }}
            </p>
          </div>

          <div class="col-md-6">
            <label for="" class="label">Código de pago</label>
            <p class="text">
              {{ currentItem.paymentCode ? currentItem.paymentCode.code : '-' }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label for="" class="label">Tipo</label>
            <p class="text">{{ getType(currentItem.type).label }}</p>
          </div>

          <div class="col-md-6">
            <label for="" class="label">Metraje</label>
            <p class="text">{{ currentItem.area || '-' }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label for="" class="label">Porcentaje</label>
            <p class="text">{{ `${currentItem.percentage}%` || '-' }}</p>
          </div>

          <div class="col-md-6">
            <label for="" class="label">Dirección fiscal</label>
            <p class="text">{{ currentItem.address || '-' }}</p>
          </div>
        </div>

        <div class="row buttons-container">
          <div class="col text-right">
            <button @click="$router.push({ name: 'config-lots-list' })" class="button button-light">
              Volver
            </button>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { Alert, Constants, Util } from "wize-admin";

import { Constants as LocalConstants } from '@/core/utils'

import { LotService } from "@/core/services";

export default {
  data() {
    return {
      itemId: this.$route.params.id,
      currentItem: null,
      selectedOption: null
    };
  },
  methods: {
    getType(key) {
      return Util.searchValue(key, LocalConstants.LotTypes);
    },
    async load() {
      this.$store.dispatch("app/loading", true);

      try {
        let response = await LotService.get(this.itemId);

        if (response.status === "OK") {
          this.currentItem = response.payload
        }
      } catch (error) {
        console.error(error);
        let message = error.errors.message;
        if (message == "No encontrado")
          message = Constants.Errors.RECORD_NOT_FOUND;
        Alert.error(message, () => this.$router.push({ name: "orders-list" }));
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    }
  },
  async mounted() {
    await this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus" scoped>
</style>
