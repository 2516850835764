<template>
  <div class="dashboard-page">
    <section class="dashboard-banner">
      <h1 class="dashboard-title">Playa Las Lagunas</h1>

      <section class="dashboard-container pt-4">
      </section>
    </section>
  </div>
</template>

<script>
export default {
  name: "DashboardPage"
};
</script>

<style lang="stylus">
@import "../Styles/dashboard-page.styl"
</style>
