<template>
  <nav class="navbar nav-header py-0">
    <div
      class="w-100 d-flex align-items-center justify-content-between justify-content-lg-end position-relative"
    >
      <!-- Mobile -->
      <div class="d-flex align-items-center d-lg-none">
        <img src="@/core/assets/images/layout/logo-admin.png" alt="Las Lagunas" class="main-logo">
        <span class="sidebar-title text-white ml-2">LAS LAGUNAS</span>
      </div>

      <div class="d-lg-none">
        <button type="button" class="bg-transparent px-0 border-0" @click="toggleMenu(true)">
          <MenuIcon />
        </button>
      </div>
      <UserDropdown :user="user" @logout="logout()" class="d-none d-lg-block" />
    </div>
  </nav>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { UserDropdown, MenuIcon } from "wize-admin";

export default {
  name: "PageHeader",
  data() {
    return {
      showCart: false,
      categories: [],
      subcategories: [],
    };
  },
  components: {
    UserDropdown,
    MenuIcon,
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    })
  },
  methods: {
    ...mapMutations({
      toggleMenu: "app/toggleMenu"
    }),
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => this.$router.push({ name: "login" }));
    },
  },
};
</script>

<style scoped lang="stylus"></style>
