<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Importar creps</h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12">
          <b-card
            tag="article"
            no-body
            style="max-width: 640px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex justify-content-between align-items-center"
            >
              <h3>Carga de archivo .txt</h3>

            </b-card-header>
            <b-card-body class="custom-card-body">
              <form class="custom-form" @submit.prevent="save" novalidate>
                <file-input
                  @onChange="onChange"
                  idFile="relation-batch-file"
                  :acceptFile="['.txt']"
                  ref="fileUpload"
                />
                <form-error
                  message="El archivo es requerido"
                  v-if="$v.file.$error && !$v.file.required"
                ></form-error>
                <div class="form-group-buttons text-right">
                  <router-link
                    :to="{ name: 'vouchers-batch-list' }"
                    class="button button-light"
                  >
                    Cancelar
                  </router-link>
                  <button class="button button-primary" type="submit">
                    Procesar
                  </button>
                </div>

                <div
                  class="table-container mt-4 overflow-hidden"
                  v-if="list.length"
                >
                  <LogsTable :items="list"/>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Alert, FileInput, FormError, Util,} from "wize-admin";

import {PaymentBatchService} from "@/core/services";
import {required} from "vuelidate/lib/validators";

import LogsTable from "../Components/LogsTable";

export default {
  data() {
    return {
      file: null,
      editing: false,
      list: [],
    };
  },
  components: {
    FileInput,
    FormError,
    LogsTable,
  },
  validations: {
    file: {required},
  },
  methods: {
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;
      await this.$store.dispatch("app/loading", true);
      const data = Util.getFormData({
        file: this.file,
      });
      try {
        const payload = (await PaymentBatchService.uploadCreps(data)).payload;

        if (payload.messages.length) {
          this.list = payload.messages.map((item) => {
            const [row, message] = item.split(": ");
            return {row, message};
          });
        }

        if (this.list.length) {
          Alert.alert("El archivo se ha procesado con algunos errores");
        } else {
          Alert.success("El archivo se ha procesado correctamente");
        }
      } catch (ex) {
        let error = ex.errors
          ? `${ex.errors.message}.`
          : "Ocurrió un error, por favor inténtelo nuevamente";
        Alert.error(error);
      } finally {
        this.$refs.fileUpload.remove();
        await this.$store.dispatch("app/loading", false);
      }
    },
    onChange(file) {
      this.file = file;
    },
  },
};
</script>

<style lang="stylus" scoped></style>
